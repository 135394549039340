import "normalize.css";
import "./index.css";
import prefetch from "prefetch-image";

const INTERVAL = 10000;

let currentElementIndex = 0;
let nextImageIndex = 1;
let currentTimeout = null;

const prefetchNextImage = () => {
  if (!sliderElements[nextImageIndex]) {
    return;
  }

  if (sliderElements[nextImageIndex].type === "image") {
    prefetch([sliderElements[nextImageIndex].src]);
    nextImageIndex++;
  } else {
    while (
      sliderElements[nextImageIndex] &&
      sliderElements[nextImageIndex].type !== "image"
    ) {
      nextImageIndex++;
    }

    prefetch([sliderElements[nextImageIndex].src]);
    nextImageIndex++;
  }
};

const onVideoClick = event => {
  if (event.srcElement.paused) {
    event.stopPropagation();
    event.srcElement.play();
  }
};

const goToNext = () => {
  currentElementIndex++;

  if (currentElementIndex >= sliderElements.length) {
    currentElementIndex = 0;
  }

  const currentElement = sliderElements[currentElementIndex];

  if (!currentElement) {
    return;
  }

  prefetchNextImage();

  if (currentElement.type === "image") {
    slider.innerHTML = `<img src="${currentElement.src}">`;
    clearTimeout(currentTimeout);
    currentTimeout = window.setTimeout(goToNext, INTERVAL);
  } else if (currentElement.type === "video") {
    slider.innerHTML = `
        <video autoplay>
          <source src="${currentElement.src}" type="video/mp4">
        </video>`;

    const video = document.querySelector("#slider video");
    video && video.addEventListener("click", onVideoClick);
    video && video.addEventListener("ended", goToNext);

    clearTimeout(currentTimeout);
    currentTimeout = null;
  }
};

const slider = document.querySelector("#slider");
const sliderElements = window.__sliderElements;

if (slider && sliderElements) {
  prefetchNextImage();
  slider.addEventListener("click", goToNext);
  clearTimeout(currentTimeout);
  currentTimeout = window.setTimeout(goToNext, INTERVAL);
}
